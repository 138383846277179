// import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartTwoTone';
import NavigateBackIcon from '@material-ui/icons/NavigateBeforeTwoTone';
import SaveIcon from '@material-ui/icons/SaveTwoTone';
// import { Alert } from '@material-ui/lab';
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
// import { addDays } from 'date-fns';
// import csLocale from 'date-fns/locale/cs';
import React, { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { MoleculerListResult } from '../../@sprinx/knihovka-types';
import Page from '../../@sprinx/react-web/Page';
import PageHeadline from '../../@sprinx/react-web/PageHeadline';
import DialogConfirm from '../../@sprinx/react-mui-components/DialogConfirm';
import { authState, localeState, useApiClient } from '../../api/appState';
import { customerOrderTotalLimitState } from '../../api/customer/customer';
import useOrderSaveHandler from '../../api/orders/useOrderSaveHandler';
import useSaveForApprovalHandler from '../../api/orders/useSaveForApprovalHandler';
import { getShippings, shippingsState } from '../../api/shipping/shipping';
import {
  shoppingCustomerSelector,
  shoppingIsDeliveryPlaceSelectedSelector,
  shoppingSelectedDeliveryPlaceState,
  shoppingSelectedPaymentState,
  shoppingSelectedShippingState,
} from '../../api/shopping';
import {
  shoppingCartAreAllPalettesSelector,
  shoppingCartHasPalettesSelector,
  shoppingCartState,
  useCartReset,
  useShoppingCartSetPayment,
  useShoppingCartSetShipping,
} from '../../api/shoppingCart';
import { shoppingListsCreateRequestState, shoppingListsSelectRequestState } from '../../api/shoppingLists';
import { enqueueSnackbarMessage } from '../../api/snackbars';
import CartResetButton from '../../components/CartResetButton';
import PickDeliveryPlace from '../../components/PickDeliveryPlace';
import PickShipping from '../../components/PickShipping';
import ProductStockProvider from '../../components/ProductStockProvider/ProductStockProvider';
import ShoppingListsActionsHolder from '../../components/ShoppingListsActionsHolder';
import useRouteUrlFactory from '../../hooks/useRouteUrlFactory';
import PickPayment from '../../components/PickPayment';
import { getPayments, paymentsState } from '../../api/payment/payment';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';
import useCallbackWithLoader from '../../hooks/useCallbackWithLoader';
import CustomerProfileUpdatePhoneDialog from '../CustomerProfilePage/components/CustomerProfileUpdatePhoneDialog';
import CartSummaryExtraFields, { CartExtraValues } from './components/CartSummaryExtraFields';
import CartSummarySubjectsList from './components/CartSummarySubjectsList';
import CartSummaryTotals from './components/CartSummaryTotals';
import CartBuyForFreeDelivery from './CartBuyForFreeDelivery';

export type CartSummaryPageLayoutProps = StandardDivProps<CartSummaryPageLayoutClassKey>;
export type CartSummaryPageLayoutClassKey =
  | 'root'
  | 'navigation'
  | 'navigationAsideActions'
  | 'pickPlace'
  | 'totalsContainer'
  | 'alert'
  | 'catalogueButton'
  | 'deliveryDate'
  | 'deliveryFree';

const themeSettings = { name: 'CartSummaryPageLayout' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<CartSummaryPageLayoutClassKey, {}>({
      root: {},
      navigation: {
        display: 'flex',
      },
      navigationAsideActions: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
      },
      pickPlace: {
        margin: theme.spacing(2, 0),
      },
      totalsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'center',
        },
        '& > div': {
          width: theme.spacing(30),
          '& > button': {
            width: '100%',
            marginBottom: theme.spacing(2),
          },
        },
      },
      alert: {
        width: '100%',
        ...theme.typography.body2,
        paddingLeft: '10px',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      },
      catalogueButton: {
        minWidth: '230px',
      },
      deliveryDate: {
        display: 'inline-flex',
        flexFlow: 'column',
        justifyContent: 'flex-start',
        width: '270px',
        margin: theme.spacing(2, 0),
      },
      deliveryFree: {
        fontSize: theme.spacing(3),
      },
    }),
  themeSettings,
);

const CartSummaryPageLayout = React.forwardRef<HTMLDivElement, CartSummaryPageLayoutProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const routeUrlFactory = useRouteUrlFactory();
    const [extraValues, setExtraValues] = React.useState<CartExtraValues>({
      noteForSeller: '',
      orderNumberExternal: '',
    });
    const addShippingToCart = useShoppingCartSetShipping();
    const addPaymentToCart = useShoppingCartSetPayment();
    const cart = useRecoilValue(shoppingCartState);
    const isDeliveryPlaceSelected = useRecoilValue(shoppingIsDeliveryPlaceSelectedSelector);
    const hasPalettes = useRecoilValue(shoppingCartHasPalettesSelector);
    const allPalettes = useRecoilValue(shoppingCartAreAllPalettesSelector);
    const deliveryPlace = useRecoilValue(shoppingSelectedDeliveryPlaceState);
    const [selectedShipping, updateShipping] = useRecoilState(shoppingSelectedShippingState);
    const [selectedPayment, updatePayment] = useRecoilState(shoppingSelectedPaymentState);
    const apiClient = useApiClient();
    const [shippings, setShippings] = useRecoilState(shippingsState);
    const [payments, setPayments] = useRecoilState(paymentsState);
    const [saveOrder] = useOrderSaveHandler();
    const [saveForApproval] = useSaveForApprovalHandler();
    const history = useHistory();
    const resetCart = useCartReset();
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [showConfirmSave, setShowConfirmSave] = React.useState<boolean>(false);
    const [showApprovalSave, setShowApprovalSave] = React.useState<boolean>(false);
    const auth = useRecoilValue(authState);
    const showMessage = useSetRecoilState(enqueueSnackbarMessage);
    const customer = useRecoilValue(shoppingCustomerSelector);
    const locale = useRecoilValue(localeState);
    // const limit = { CZK: 2500, EUR: 250 };
    // const [requestedDeliveryDate, setRequestedDeliveryDate] = React.useState<Date | null>(null);
    const t = useTranslateWithFallback();
    const handleSetExtraValues = React.useCallback((values: Partial<CartExtraValues>) => {
      setExtraValues((prev) => ({ ...prev, ...values }));
    }, []);

    const handleSaveOrder = useCallbackWithLoader(async () => {
      // eslint-disable-next-line no-alert
      setDisabled(true);
      await addShippingToCart(selectedShipping, 1);
      await addPaymentToCart(selectedPayment);
      saveOrder({
        expectedDeliveryDate: new Date(),
        noteForSeller: extraValues.noteForSeller,
        extra: {
          ...(deliveryPlace || {}).extra,
          externalOrderNumber: extraValues.orderNumberExternal,
          // requestedDeliveryDate,
        },
      })
        .catch((err) => {
          setShowConfirmSave(false);
          showMessage({ message: `${err}`, variant: 'error' });
        })
        .then((createdOrder) => {
          setShowConfirmSave(false);
          const returnMessage = createdOrder as OrderCreateMessage;
          if (returnMessage && returnMessage.status == 'error') {
            showMessage({ message: `${returnMessage.msg}`, variant: 'error' });
            setDisabled(false);
          } else {
            history.push(routeUrlFactory('orderThankYou'), { order: createdOrder });
            resetCart(true);
          }
        });
    });

    const handleSaveForApproval = React.useCallback(async () => {
      // eslint-disable-next-line no-alert
      setDisabled(true);
      await addShippingToCart(selectedShipping, 1);
      await addPaymentToCart(selectedPayment);
      saveForApproval({
        expectedDeliveryDate: new Date(),
        noteForSeller: extraValues.noteForSeller,
        extra: {
          ...(deliveryPlace || {}).extra,
          externalOrderNumber: extraValues.orderNumberExternal,
          // requestedDeliveryDate,
        },
      })
        .catch((err) => {
          setShowApprovalSave(false);
          showMessage({ message: `${err}`, variant: 'error' });
        })
        .then((createdOrder) => {
          setShowApprovalSave(false);
          const returnMessage = createdOrder as OrderCreateMessage;
          if (returnMessage && returnMessage.status == 'error') {
            showMessage({ message: `${returnMessage.msg}`, variant: 'error' });
            setDisabled(false);
          } else {
            history.push(routeUrlFactory('orderSentToApproval'), { order: createdOrder });
            resetCart(true);
          }
        });
    }, [
      setDisabled,
      setShowApprovalSave,
      addShippingToCart,
      selectedShipping,
      addPaymentToCart,
      selectedPayment,
      saveForApproval,
      extraValues.noteForSeller,
      extraValues.orderNumberExternal,
      deliveryPlace,
      showMessage,
      history,
      routeUrlFactory,
      resetCart,
    ]);

    const shoppingListSaveDialogOpen = useSetRecoilState(shoppingListsCreateRequestState);
    const shoppingListSelectDialogOpen = useSetRecoilState(shoppingListsSelectRequestState);

    const orderSubmitDisabled = useMemo(() => {
      const userIsImpersonated =
        auth && auth.tokenPayload ? ((auth.tokenPayload?.sub || '') as any).startsWith('IMPERSONATED') : false;
      return (
        disabled ||
        !(
          cart &&
          cart.cartContent &&
          cart.cartContent.subjects &&
          cart.cartContent.subjects.length > 0 &&
          isDeliveryPlaceSelected &&
          selectedShipping != null &&
          selectedPayment != null &&
          (selectedShipping.allowedPaymentMethods || []).includes(selectedPayment.sku) &&
          (!hasPalettes || allPalettes)
        ) ||
        userIsImpersonated
      );
    }, [allPalettes, cart, disabled, hasPalettes, isDeliveryPlaceSelected, selectedPayment, selectedShipping, auth]);

    const [showPhoneDialog, setShowPhoneDialog] = useState(false);
    const shouldShowPhoneDialog = !auth?.user?.phone;

    const resetCartDisabled = !(
      cart &&
      cart.cartContent &&
      cart.cartContent.subjects &&
      cart.cartContent.subjects.length > 0
    );

    const orderTotalLimit = useRecoilValue(customerOrderTotalLimitState);
    const orderTotalInLimit =
      typeof cart?.cartContent.total === 'number' &&
      (typeof orderTotalLimit === 'undefined' || cart?.cartContent.total < orderTotalLimit);

    useEffect(() => {
      async function updateShippings() {
        if (customer != null && cart?.id != null) {
          const getShippingRes: MoleculerListResult<any> = await getShippings(apiClient, customer, cart.id);

          const newShippings = getShippingRes.rows;
          newShippings ? setShippings(newShippings) : setShippings([]);
          const newShipping =
            selectedShipping != null ? newShippings.find((s) => s.id === selectedShipping.id) : undefined;

          addShippingToCart(newShipping, newShipping != null ? 1 : 0);
          updateShipping(newShipping);
        }
      }
      updateShippings();
    }, [apiClient, customer, setShippings, cart?.cartContent.totalSubjects]); // cart?.cartContent.totalSubjects has to be there due to the correcting shipping costs (freeShippingLimits)

    // TODO: v rámci refaktoru se zamyslet, zda nesetovat paymenty do recoilu pouze jednou a setování konkrétního paymentu přenést do druhého useEffectu
    // (obdobně u shippings, ale tam se musí počítat se správným updatováním cen za dopravu podle ceny košíku - doprava zdarma od určité částky)

    useEffect(() => {
      async function updatePayments() {
        if (customer != null && cart?.id != null && selectedShipping != null) {
          const getPaymentsRes: MoleculerListResult<any> = await getPayments(apiClient, customer, cart.id);

          const newPayments = getPaymentsRes.rows || [];
          setPayments(newPayments);

          const newPayment =
            selectedPayment != null && (selectedShipping.allowedPaymentMethods || []).includes(selectedPayment.sku)
              ? selectedPayment
              : undefined;

          addPaymentToCart(newPayment);
          updatePayment(newPayment);
        }
      }
      updatePayments();
    }, [apiClient, customer, selectedShipping, setPayments]);

    // TODO: v rámci refaktoru se zamyslet, zda tyto dva useEffecty nemají být odebrány
    // Popis problému: updateCart sice na api proběhne, ale FE se přerendruje podle ještě NEupdatovaného cartu z useRecoilValue, dochází tedy k setování neexistujících hodnot.
    // Tento problém je však odebrán přidáním platby a dopravy podle recoilu do košíku před vytvořením objednávky
    useEffect(() => {
      if (cart?.cartContent?.shipping?.product && !selectedShipping && shippings.length > 0) {
        const shipping = shippings.find(
          (x) =>
            x.id ===
            (typeof cart.cartContent.shipping.product === 'object'
              ? cart.cartContent.shipping.product.id
              : cart.cartContent.shipping.product),
        );
        if (shipping) {
          updateShipping(shipping);
        }
      }
    }, [addShippingToCart, cart?.cartContent.shipping.product, selectedShipping, shippings, updateShipping]);

    useEffect(() => {
      if (selectedShipping && cart?.cartContent?.payment?.product && !selectedPayment && payments.length > 0) {
        const payment = payments.find(
          (x) =>
            x.id ===
            (typeof cart.cartContent.payment.product === 'object'
              ? cart.cartContent.payment.product.id
              : cart.cartContent.payment.product),
        );
        updatePayment(
          payment && (selectedShipping.allowedPaymentMethods || []).includes(payment.sku) ? payment : undefined,
        );
      }
    }, [
      addPaymentToCart,
      cart?.cartContent.payment.product,
      selectedPayment,
      payments,
      updatePayment,
      selectedShipping,
    ]);

    return (
      <Page ref={ref} className={clsx(classes.root, className)} pageTitle={t('cartSummaryPage/previewBeforeOrder')}>
        <ProductStockProvider>
          <PageHeadline>{t('cartSummaryPage/previewBeforeOrder')}</PageHeadline>
          <div className={classes.navigation}>
            <Button
              component={RouterLink}
              to={`/${locale}/catalogue`}
              color='default'
              startIcon={<NavigateBackIcon />}
              variant='outlined'
              className={classes.catalogueButton}
            >
              {t('cartApprovalPage/continueShopping')}
            </Button>

            {/* {showAdditionalCostsAlert && (
              <div className={classes.alert}>
                <Alert severity='warning'>
                  {currency == 'CZK' &&
                    `Neobjednali jste v limitu ${limit[currency]} Kč, může vám být účtována doprava.`}
                  {currency == 'EUR' && `Neobjednali jste v limitu ${limit[currency]}€, může vám být účtována doprava.`}
                </Alert>
              </div>
            )} */}

            <div className={classes.navigationAsideActions}>
              <IconButton color='inherit' onClick={() => shoppingListSaveDialogOpen(true)}>
                <SaveIcon />
              </IconButton>
              <IconButton color='inherit' onClick={() => shoppingListSelectDialogOpen(true)}>
                <AddShoppingCartIcon />
              </IconButton>
            </div>
          </div>
          <PickDeliveryPlace className={classes.pickPlace} />
          <PickShipping className={classes.pickPlace} shippings={shippings} />
          <PickPayment className={classes.pickPlace} payments={payments} />
          <CartSummarySubjectsList cart={cart} />
          <CartSummaryTotals cart={cart} />
          <CartBuyForFreeDelivery className={classes.deliveryFree} />
          <CartSummaryExtraFields values={extraValues} onChange={handleSetExtraValues} />

          {/* <div className={classes.deliveryDate}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={csLocale}>
              <DatePicker
                label='Požadované datum dodávky'
                value={requestedDeliveryDate}
                format={'dd.MM.yyyy'}
                clearable
                minDate={new Date()}
                maxDate={addDays(new Date(), 365)}
                onChange={setRequestedDeliveryDate}
                helperText='Vyplňte dle aktuálních termínů závozu'
              />
            </MuiPickersUtilsProvider>
          </div> */}
          <div className={classes.totalsContainer}>
            <div>
              {orderTotalInLimit ? (
                <Button
                  color='secondary'
                  variant='contained'
                  size='large'
                  onClick={() => {
                    if (shouldShowPhoneDialog) {
                      setShowPhoneDialog(true);
                      return;
                    }
                    setShowConfirmSave(true);
                  }}
                  disabled={orderSubmitDisabled}
                >
                  {t('common/createOrder')}
                </Button>
              ) : (
                <Button
                  color='secondary'
                  variant='contained'
                  size='large'
                  onClick={() => {
                    setShowApprovalSave(true);
                  }}
                  disabled={orderSubmitDisabled}
                >
                  {'Odeslat ke schválení'}
                </Button>
              )}
              <CartResetButton disabled={resetCartDisabled} />
            </div>
          </div>
          <ShoppingListsActionsHolder />

          <DialogConfirm
            open={showConfirmSave}
            onClose={() => {
              setShowConfirmSave(false);
            }}
            onConfirm={() => {
              if (!disabled) {
                handleSaveOrder();
              }
            }}
            title={t('common/newOrderConfirmation')}
            confirmButtonLabel={t('common/send')}
          >
            <div>{t('cartApprovalPage/areYouSureToSend')}</div>
          </DialogConfirm>
          <DialogConfirm
            open={showApprovalSave}
            onClose={() => {
              setShowApprovalSave(false);
            }}
            onConfirm={() => {
              if (!disabled) {
                handleSaveForApproval();
              }
            }}
            title={t('common/newOrderConfirmation')}
          >
            <div>{t('cartApprovalPage/confirmText')}</div>
          </DialogConfirm>
          <CustomerProfileUpdatePhoneDialog
            title={t('customerProfilePage/updatePhone/requiredDialog')}
            open={showPhoneDialog}
            onClose={() => setShowPhoneDialog(false)}
          />
        </ProductStockProvider>
      </Page>
    );
  },
);

export interface OrderCreateMessage {
  msg: string;
  status: string;
}

CartSummaryPageLayout.displayName = 'CartSummaryPageLayout';

export default withThemeProps(themeSettings)(CartSummaryPageLayout);
