import React from 'react';
import {
  AfterPageProps,
  createApiClient,
  createInitialPropsRedirectToLogin,
  makeAfterComponent,
  preventAuth,
  wrapLayout,
} from '../../@sprinx/react-after-razzle';
import commonThingsToLoad from '../../api/commonThingsToLoad';
import { shoppingListsGetList, shoppingListsInitialState } from '../../api/shoppingLists';
import ErrorInternal from '../../components/ErrorInternal';
import CustomerProfileShoppingListsPageLayout from './CustomerProfileShoppingListsPageLayout';

export type CustomerProfileShoppingListsPageProps = AfterPageProps;

const CustomerProfileShoppingListsPage: React.FC<CustomerProfileShoppingListsPageProps> = ({ error }) => {
  if (error) return <ErrorInternal error={error} />;

  return <CustomerProfileShoppingListsPageLayout />;
};

CustomerProfileShoppingListsPage.displayName = 'CustomerProfileShoppingListsPage';

export default makeAfterComponent(
  wrapLayout(CustomerProfileShoppingListsPage),
  preventAuth(async ({ match, initialAppProps: appProps }) => {
    try {
      if (appProps) {
        const apiClient = createApiClient({ accessToken: appProps.auth?.accessToken });

        const [shoppingListsResult, ...commonThings] = await Promise.all([
          shoppingListsGetList(apiClient),
          ...commonThingsToLoad.promises({ apiClient }),
        ]);

        appProps.propagateToState({
          [shoppingListsInitialState.key]: shoppingListsResult,
          ...commonThingsToLoad.toRegister(commonThings),
        });
      }

      return {};
    } catch (error) {
      if (error.response?.status === 401) {
        return createInitialPropsRedirectToLogin({ match });
      }
      return { error };
    }
  }),
);
